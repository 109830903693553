import {useSelector } from 'react-redux';

export const HasAccess = (requiredRoles) => {
  const permissions = useSelector((state) => state.auth.userInfo.permissions);
  const userRoles = permissions.map(item => item.name);
  if (Array.isArray(userRoles)) {
    // If userRoles is an array, check if the intersection is not empty
    return requiredRoles.some(role => userRoles.includes(role));
  } else if (typeof userRoles === 'string') {
    // If userRoles is a string, check if it matches any of the required roles
    return requiredRoles.includes(userRoles);
  }

  // Handle other cases or return false if userRoles is not in an expected format
  return false;
};

export const GetUser=()=>{
  let CurrentUser="";
  const user = useSelector((state) => state.auth.userInfo);
  if(user){
    CurrentUser=user.employee.first_name+" "+user.employee.father_name;
  }
  return CurrentUser;
}

export const GetUserInfo=()=>{
  let CurrentUser={user:""};
  const user = useSelector((state) => state.auth.userInfo);
  if(user){
    CurrentUser=user;
  }
  return CurrentUser;
}