import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form, Button,Spinner } from "react-bootstrap";
import Card from "../../../../components/Card";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { all_employee } from "../../../../services/employeeApi";
import { AddNewUser } from "../../../../services/userApi";
import SearchableDropdown from "../../../sharedComponent/SearchableDropdown";
import AlertModal from "../../../sharedComponent/AlertModal";
const UserAdd = ({ parentID, onSubmitButton }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [selectedOption, setSelectedOption] = useState(null);
  const [userName, setUserName] = useState("");
  const [formError, setformError] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setshowAlert] = useState();
  const handleClose = () => setshowAlert(false);

  const getEmployeeQuery = useQuery(["useradd_get_all_employee"], () =>all_employee());
  const AddNewUserMutation = useMutation(async (newData) => {
    try {
      const result = await AddNewUser(newData);
      // Assuming result.message contains the message from the server
      if (result) {
        queryClient.invalidateQueries("get_all_user");
        toast.success(result, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        handleCloseModal();
      }
    } catch (error) {
      setalertTitle("Error");
      setAlertMessage(error.message);
      setshowAlert(true);
    }
  });

  if (getEmployeeQuery.isLoading) {
    return <div>Loading...</div>;
  }
  if (getEmployeeQuery.isError) {
    return <div>Error: {getEmployeeQuery.error.message}</div>;
  }
  const employeeData = getEmployeeQuery.data;
  const options = employeeData.map((employee) => ({
    value: employee.id,
    label: `${employee.first_name} ${employee.last_name || ""}`,
  }));
  const handleDropdownChange = (selected) => {
    setSelectedOption(selected);
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Validate form data
    if (!selectedOption || !userName) {
      setformError("Please fill in all required fields");
      return;
    }
    const newUser = {username: userName, password: "Pass@1234",emp_id: selectedOption.value};
    try {
      setIsSubmitting(true);
      await AddNewUserMutation.mutate(newUser);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    onSubmitButton();
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xl="12" lg="12">
            <Card>
              <Card.Body>
                <div className="new-user-info">
                  <Form onSubmit={handleFormSubmit}>
                    <Row>
                      <Form.Group as={Col} sm="12" controlId="selectEmployee">
                        <Form.Label>Select an Employee</Form.Label>
                        <SearchableDropdown
                          options={options}
                          onChange={handleDropdownChange}
                          value={selectedOption}
                          placeholder="Select an option"
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="12" controlId="userName">
                        <Form.Label>User Name:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="User Name"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                        <p className="text-danger">{formError}</p>
                      </Form.Group>
                    </Row>
                    {isSubmitting ? (
                        <span>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="visually-hidden">Sending...</span>
                      </span>
                    ):(
                    <Button variant="btn btn-success m-2" type="submit">
                      {t("add_new_user")}
                    </Button>)}
                    <Button variant="btn btn-primary m-2" onClick={handleCloseModal}>
                      {t("cancel")}
                    </Button>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <AlertModal
        show={showAlert}
        onHide={handleClose}
        title={alertTitle}
        message={AlertMessage}
      />
    </>
  );
};

export default UserAdd;
