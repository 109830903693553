import {fetchData,postData,updateData } from '../utils/apiUtils';
 export const all_user = async () => {
    const url = `employee/user/getall`;
    const responseData = await fetchData(url);
    return responseData;
    };

    export const AddNewUser = async (newUser) => {
      const url = `employee/user/register`;
      const responseData = await postData(url,newUser);
      return responseData;
    };

    export const ChangeUsrPassword = async (newPassword) => {
      const url = `employee/user/changepassword`;
      const responseData = await postData(url,newPassword);
      return responseData;
    };
  
    export const ResetUsrPassword = async (newPassword) => {
      const url = `employee/user/resetpassword`;
      const responseData = await postData(url,newPassword);
      return responseData;
    };