import React ,{ useState } from 'react'
import {Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { all_user } from "../../../../services/userApi";
import CustomPagination from "../../../../utils/CustomPagination"
import {useQuery} from "react-query";
import { Container ,Row, Form,Col,Table,OverlayTrigger,Tooltip,Dropdown,Button} from 'react-bootstrap'
import  Card from '../../../../components/Card'
import SharedModal from '../../../sharedComponent/SharedContentModal';
import UserAdd from './UserAdd';
const UserList = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleButtonSubmit = () => {
      handleCloseModal();
};
    const getUserQuery = useQuery(['get_all_user'],() => all_user());
    if (getUserQuery.isLoading) {
      return <div>Loading...</div>;
    }
    if (getUserQuery.isError) {
      return <div>Error: {getUserQuery.error.message}</div>;
    }
    const userList= getUserQuery.data;
    const viewUser=(event,data)=>{
        event.preventDefault()
           console.log(data);
    }
    return (
        <>
          <Container fluid>
            <Row>
                <Col lg="12">
                    <Card className="card-block card-stretch card-height">
                        <Card.Body>
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <h4 className="font-weight-bold"> User List</h4>
                      <div className="d-flex flex-wrap align-items-center justify-content-between">       
                    <input type="text" className="form-control-sm m-2" placeholder="Search user"/>
                                     <Button  variant="btn btn-success btn-sm m-2"> Search</Button>
                                       <Button variant="btn btn-primary btn-sm m-2" onClick={()=>handleShowModal()}>
                                         {t("add_new_user")}</Button>
                    </div> 
                    </div>
                            <Table className="data-tables" responsive style={{width:"100%" }}>
                                <thead className="light">
                                    <tr>
                                        <th>#</th>
                                        <th>{t('full_name')}</th>
                                        <th> {t('username')}</th>
                                        <th> {t('phone')}</th>
                                        <th>{('permission')}</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userList.map((item,index)=>(
                                              <tr key={index}>
                                            <td>
                                            {index+1}
                                        </td>
                                        <td>{item.employee.first_name} {item.employee.father_name} </td>
                                        <td>{item.username}</td>
                                        <td>{item.employee.phone}</td>
                                        <td>{item.permissions.length}</td>
                                        <td>
                                            <div className="d-flex align-items-center list-action">
                                             <OverlayTrigger placement={"top"} overlay={<Tooltip>View</Tooltip>}>
                                                    <Link to="/" onClick={(e) => viewUser(e,item)} className="badge bg-success-light mr-2"><i className="lar la-eye"></i></Link>
                                                </OverlayTrigger>
                                            </div>
                                        </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
  <SharedModal
       show={showModal}
       title="add_new_user_form"
        onHide={handleCloseModal}
        centered={true}
        backdrop="static"
        keyboard={false}
        content={<UserAdd onSubmitButton={handleButtonSubmit} parentID={"id"} />}
              />
        </>
    )
}

export default UserList