import React,{useState} from 'react'
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Nav, Tab, Form,Button,Spinner } from 'react-bootstrap'
import Card from '../../../../components/Card'
import user1 from '../../../../assets/images/user/1.jpg'
import { ChangeUsrPassword } from "../../../../services/userApi";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import AlertModal from "../../../sharedComponent/AlertModal";
const UserProfileEdit = () => {
    const { t } = useTranslation();
  const [AlertMessage, setAlertMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");
  const [showAlert, setshowAlert] = useState();
  const handleClose = () => setshowAlert(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [formError, setformError] = useState("");
    const ChangePasswordMutation = useMutation(async (newData) => {
        try {
          const result = await ChangeUsrPassword(newData);
          if (result) {
            toast.success(result, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        } catch (error) {
          setalertTitle("Error");
          setAlertMessage(error.message);
          setshowAlert(true);
        }
      });
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // Validate form data
        if (!currentPassword || !newPassword || !verifyPassword) {
          setformError("All inputs are required ")
          return;
        }
        if (newPassword !== verifyPassword) {
          setformError("Password Mismatch")
          return;
        }
     const newPasword={
         username:"melkamut",
         oldPassword:currentPassword,
         newPassword:newPassword
         }
    try {
      setIsSubmitting(true);
      await ChangePasswordMutation .mutate(newPasword);
    } finally {
      setIsSubmitting(false);
    }
}


    return (
        <>
            <Container fluid>
                <Tab.Container defaultActiveKey="chang-pwd">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body className="p-0">
                                    <div className="mm-edit-list usr-edit">
                                        <Nav variant="pills" className="mm-edit-profile d-flex">
                                        <li className="col-md-3 p-0">
                                                <Nav.Link eventKey="chang-pwd">Change Password</Nav.Link>
                                            </li>
                                            {/* <li className="col-md-3 p-0">
                                                <Nav.Link eventKey="personal-information">Personal Information</Nav.Link>
                                            </li> */}
                                           
                                            {/* <li className="col-md-3 p-0">
                                                <Nav.Link eventKey="emailandsms">Email and SMS</Nav.Link>
                                            </li> */}
                                            {/* <li className="col-md-3 p-0">
                                                <Nav.Link eventKey="manage-contact">Manage Contact</Nav.Link>
                                            </li> */}
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <div className="mm-edit-list-data">
                                <Tab.Content>
                                    <Tab.Pane eventKey="personal-information" role="tabpanel">
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">Personal Information</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form>
                                                    <Row className="form-group  align-items-center">
                                                        <Col md="12">
                                                            <div className="profile-img-edit">
                                                                <div className="crm-profile-img-edit">
                                                                    <img className="crm-profile-pic rounded-circle avatar-100" src={user1} alt="profile-pic" />
                                                                    <div className="crm-p-image bg-primary">
                                                                        <i className="las la-pen upload-button"></i>
                                                                        <input className="file-upload" type="file" accept="image/*" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className=" align-items-center">
                                                        <Form.Group controlId="fname" as={Col} sm="6">
                                                            <Form.Label>First Name:</Form.Label>
                                                            <Form.Control type="text" defaultValue="" />
                                                        </Form.Group>
                                                        <Form.Group controlId="lname" as={Col} sm="6">
                                                            <Form.Label>Last Name:</Form.Label>
                                                            <Form.Control type="text" defaultValue="" />
                                                        </Form.Group>
                                                        <Form.Group controlId="uname" as={Col} sm="6">
                                                            <Form.Label>User Name:</Form.Label>
                                                            <Form.Control type="text" defaultValue="" />
                                                        </Form.Group>
                                                        <Form.Group controlId="cname" as={Col} sm="6">
                                                            <Form.Label>City:</Form.Label>
                                                            <Form.Control type="text" defaultValue="" />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="6">
                                                            <Form.Label bsPrefix="d-block">Gender:</Form.Label>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <Form.Check.Input type="radio" id="customRadio6" name="customRadio1" bsPrefix="custom-control-input" defaultChecked />
                                                                <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customRadio6"> Male </Form.Check.Label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <Form.Check.Input type="radio" id="customRadio7" name="customRadio1" bsPrefix="custom-control-input" />
                                                                <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customRadio7"> Female </Form.Check.Label>
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="6" controlId="dob">
                                                            <Form.Label>Date Of Birth:</Form.Label>
                                                            <Form.Control defaultValue="1984-01-24" />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="6" controlId="exampleFormControlSelect1">
                                                            <Form.Label>Marital Status:</Form.Label>
                                                            <Form.Control as="select">
                                                                <option defaultValue="">Single</option>
                                                                <option>Married</option>
                                                                <option>Widowed</option>
                                                                <option>Divorced</option>
                                                                <option>Separated </option>
                                                            </Form.Control>
                                                        </Form.Group>
                                    
                                                        <Form.Group as={Col} controlId="exampleFormControlSelect3" sm="6">
                                                            <Form.Label>Country:</Form.Label>
                                                            <Form.Control as="select">
                                                                <option>Ethiopia</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="exampleFormControlSelect4" sm="6">
                                                            <Form.Label>State/Region</Form.Label>
                                                            <Form.Control as="select">
                                                              
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="12">
                                                            <Form.Label>Address:</Form.Label>
                                                            <Form.Control as="textarea" name="address" defaultValue=""  rows="5" style={{ lineHeight: "22px" }}>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Row>
                                                    <Button type="reset" variant="outline-primary mr-2">Cancel</Button>
                                                    <Button type="submit" variant="primary">Submit</Button>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="chang-pwd" role="tabpanel">
                                    <Container fluid>
        <Row>
            <Col lg="6">
      <Card className="mx-auto" style={{ maxWidth: "500px" }}>
        <Card.Header>
          <Card.Header.Title>
            <h4 className="card-title">Change Password</h4>
          </Card.Header.Title>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="cpass">
              <Form.Label>Current Password:</Form.Label>
              <Form.Control
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="npass">
              <Form.Label>New Password:</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="vpass">
              <Form.Label>Verify Password:</Form.Label>
              <Form.Control
                type="password"
                value={verifyPassword}
                onChange={(e) => setVerifyPassword(e.target.value)}
              />
              <p className='text-danger'> {formError}</p>
            </Form.Group>
            <Button type="reset" variant="outline-primary mr-2">
             {t('cancel')}
            </Button>
           
            {isSubmitting ? (
                       
                 <Button variant="info"> <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /> 
                        <span className="visually-hidden">Sending...</span>
                         </Button>
                     
                    ):(
                        <Button type="submit" variant="info">
                        {t('Submit')}
                      </Button>)}
          </Form>
        </Card.Body>
      </Card>
      </Col>
      </Row>
      </Container>
    </Tab.Pane>
                                    <Tab.Pane eventKey="emailandsms" role="tabpanel">
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">Email and SMS</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group as={Row} className="align-items-center" controlId="emailnotification">
                                                        <Form.Label className="col-md-3">Email Notification:</Form.Label>
                                                        <div className="col-md-9 custom-control custom-switch">
                                                            <Form.Check.Input  bsPrefix="custom-control-input"  defaultChecked />
                                                            <Form.Label bsPrefix="custom-control-label"></Form.Label>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="align-items-center" controlId="smsnotification">
                                                        <Form.Label bsPrefix="col-md-3">SMS Notification:</Form.Label>
                                                        <div className="col-md-9 custom-control custom-switch">
                                                            <Form.Check.Input  bsPrefix="custom-control-input" defaultChecked />
                                                            <Form.Label bsPrefix="custom-control-label"></Form.Label>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="align-items-center">
                                                        <Form.Label bsPrefix="col-md-3" htmlFor="npass">When To Email</Form.Label>
                                                        <div className="col-md-9">
                                                            <div className="custom-control custom-checkbox">
                                                                <Form.Check.Input  bsPrefix="custom-control-input" id="email01" />
                                                                <Form.Label bsPrefix="custom-control-label" htmlFor="email01">You have new notifications.</Form.Label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox">
                                                                <Form.Check.Input  bsPrefix="custom-control-input" id="email02" />
                                                                <Form.Label bsPrefix="custom-control-label" htmlFor="email02">You're sent a direct message</Form.Label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox">
                                                                <Form.Check.Input  bsPrefix="custom-control-input" id="email03" defaultChecked />
                                                                <Form.Label bsPrefix="custom-control-label" htmlFor="email03"> when assigned to new role</Form.Label>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="align-items-center">
                                                        <Form.Label bsPrefix="col-md-3" htmlFor="npass">When To Escalate Emails</Form.Label>
                                                        <div className="col-md-9">
                                                            <div className="custom-control custom-checkbox">
                                                                <Form.Check.Input bsPrefix="custom-control-input" id="email04" />
                                                                <Form.Label bsPrefix="custom-control-label" htmlFor="email04"> Upon new order.</Form.Label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox">
                                                                <Form.Check.Input bsPrefix="custom-control-input" id="email05" />
                                                                <Form.Label bsPrefix="custom-control-label" htmlFor="email05"> New approval action </Form.Label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox">
                                                                <Form.Check.Input bsPrefix="custom-control-input" id="email06" defaultChecked />
                                                                <Form.Label bsPrefix="custom-control-label" htmlFor="email06"> New Item registration</Form.Label>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Button type="reset" variant="outline-primary mr-2">Cancel</Button>
                                                    <Button type="submit" variant="primary">Submit</Button>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="manage-contact" role="tabpanel">
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">Manage Contact</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group controlId="cno">
                                                        <Form.Label>Contact Number:</Form.Label>
                                                        <Form.Control type="text" defaultValue="" />
                                                    </Form.Group>
                                                    <Form.Group controlId="email">
                                                        <Form.Label>Email:</Form.Label>
                                                        <Form.Control type="text" defaultValue="" />
                                                    </Form.Group>
                                                    <Form.Group controlId="url">
                                                        <Form.Label>Website Url:</Form.Label>
                                                        <Form.Control type="text" defaultValue="" />
                                                    </Form.Group>
                                                    <Button type="reset" variant="outline-primary mr-2">Cancel</Button>
                                                    <Button type="submit" variant="primary">Submit</Button>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
            <AlertModal
        show={showAlert}
        onHide={handleClose}
        title={alertTitle}
        message={AlertMessage}
      />
        </>
    )
}

export default UserProfileEdit