import React, {useState} from 'react'
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ResetUsrPassword } from "../../../services/userApi";
import {Container,Col,Row, Form,Button,Spinner } from 'react-bootstrap'
import Card from '../../../components/Card'
import { toast } from "react-toastify";
import AlertModal from "../../sharedComponent/AlertModal";
 const RecoverPassword = () => {
   const { t } = useTranslation();
   const [AlertMessage, setAlertMessage] = useState("");
   const [alertTitle, setalertTitle] = useState("");
   const [showAlert, setshowAlert] = useState();
   const handleClose = () => setshowAlert(false);
   const [username, setUsername] = useState('');
   const [formError, setformError] = useState("");
   const [isSubmitting, setIsSubmitting] = useState(false);


   const ResetUsrPasswordMutation = useMutation(async (newData) => {
      try {
        const result = await ResetUsrPassword(newData);
        if (result) {
          toast.success(result.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } catch (error) {
        setalertTitle("Error");
        setAlertMessage(error.message);
        setshowAlert(true);
      }
    });
   const handleResetPassword = async (e) => {
        e.preventDefault();
      if (!username) {
            setformError("User name is required ")
            return;
          }
       const Userinfo={username:username,}
      try {
        setIsSubmitting(true);
        await ResetUsrPasswordMutation.mutate(Userinfo);
      } finally {
        setIsSubmitting(false);
      }
         
   }
    return (
        <>
        <Container className="h-100">
            <Row className="align-items-center justify-content-center h-100">
               <Col md="5">
                  <Card className="p-5">
                     <Card.Body>
                      <h3 className="mb-3 text-center">Reset Password</h3>
                        <Form>
                           <Row>
                              <Col lg="12">
                                 <Form.Group >
                                    <Form.Label className="text-secondary">User Name</Form.Label>
                                    <Form.Control  type="text" placeholder="Enter User Name"  value={username}
                                       onChange={(e) => setUsername(e.target.value)} />
                                       <p className='text-danger'> {formError}</p>
                                 </Form.Group >
                              </Col>
                           </Row>
                           {isSubmitting ? (
                         <Button variant="btn-primary"> <Spinner
                         as="span"
                         animation="grow"
                         size="sm"
                         role="status"
                         aria-hidden="true"
                       /> 
                       <span className="visually-hidden">Sending...</span>
                        </Button>
                    ):(
                     <Button onClick={(e) => handleResetPassword(e)} variant="btn btn-primary btn-block">Reset Password</Button>)}   
                        </Form>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </Container>
         <AlertModal
        show={showAlert}
        onHide={handleClose}
        title={alertTitle}
        message={AlertMessage}
      />
        </>
    )
}

export default RecoverPassword;