import React, {useState} from 'react';
import { useTranslation} from 'react-i18next';
import SelectLanguage from "../../../settings/languageSetting"
import {useDispatch } from 'react-redux';
import { Container,Col,Row,Button, Form,Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import  Card from '../../../components/Card'
import {connect} from "react-redux";
import {getDarkMode} from '../../../store/mode'
import { useHistory } from 'react-router-dom'
import { login } from '../../../store/authentication/authSlice';
import logo from '../../../assets/images/logo.png'
import darklogo from '../../../assets/images/logo.jpg'

function mapStateToProps(state) {
   return {
       darkMode: getDarkMode(state)
   };
}

const SignIn = (props) => {

   let history =useHistory()
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [username, setUsername] = useState('');
   const [password, setPassword] = useState('');
   const [error, setError] = useState('');
   const [isSubmitting, setIsSubmitting] = useState(false);
   const handleLogin = async (e) => {
       e.preventDefault();
      if (!username || !password) {
        setError('Please enter both username and password.');
        return;
      }
      setIsSubmitting(true);
      try {
      const credentials = { username, password };
      const response= await dispatch(login(credentials));
      if(response.error){
         setIsSubmitting(false);
        setError(response.error.message);
         return 0;
      }
       history.push('/');
      } finally {
         setIsSubmitting(false);
       }
    };
    
    return (
        <>
        <section className="login-content">
         <Container className="h-100"> 
            <Row className="align-items-center justify-content-center h-100">
               <Col md="5">
                  <Card className="p-3">
                     <Card.Body>
                        <div className="auth-logo">
                           <img src={logo} className={`img-fluid  rounded-normal  ${!props.darkMode ? 'd-none' : ''}`} alt="logo"/>
                           <img src={darklogo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo"/>
                        </div>
                        <h6 className="mb-3 font-weight-bold text-center">{t("app_title")}</h6>
                         <div className="mb-5">
                            <p className="line-around text-secondary mb-0"><span className="line-around-1">Log in to your account to continue</span></p>
                        </div>
                        <Form onSubmit={handleLogin}>
                           <Row>
                              <Col lg="12">
                                 <Form.Group>
                                    <Form.Label className="text-secondary">User name</Form.Label>
                                    <Form.Control 
                                 type="text"
                                 placeholder="Enter username"
                                 value={username}
                                 onChange={(e) => setUsername(e.target.value)}    
                                 />
                                 </Form.Group>
                              </Col>
                              <Col lg="12" className="mt-2">
                                 <Form.Group>
                                     <div className="d-flex justify-content-between align-items-center">
                                         <Form.Label className="text-secondary">Password</Form.Label>
                                         
                                     </div>
                                    <Form.Control
                                      value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password" placeholder="Enter Password"/>
                                 </Form.Group>
                                 <div style={{ color:"red" }}>
                                 {error}
                                 </div>
                              </Col>
                           </Row>
                           {isSubmitting ? (
                               <Button variant="btn btn-primary btn-block mt-2">
                        <span>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="visually-hidden">Sending...</span>
                      </span>
                      </Button>
                    ):(
                     <Button type="submit" variant="btn btn-primary btn-block mt-2">Log In</Button>
                    )}
                           
                          <Row>
                           <Col lg="6" className="mt-3">
                                <Form.Label><Link to="">Change language</Link></Form.Label>
                           </Col>
                           <Col lg="6" className="mt-3">
                           <SelectLanguage/>
                           </Col>
                           </Row>
                          </Form>
                          <br></br>
                          <br></br>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </Container>
      </section>
        </>
    )
}

export default connect(mapStateToProps)(SignIn)